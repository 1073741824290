import { useEffect, useState } from "react"
import axios from 'axios'
import api from '../components/api'


const DemandaDocumentos = (props) => {


    const demandaStatus_id = props.demandaStatus_id
    const demanda_id = props.demanda_id
    const tipoDoc = props.tipoDoc
    const usuario_id = sessionStorage.getItem('usuario_id')


    const [resultadoDocumentos, setResultadoDocumentos] = useState([])

    const [descricaoAnexo, setDescricaoAnexo] = useState('')
    const [file, setFile] = useState()
   


    const salvaDocumento = () => {


        if (file == null || file == '') {


            window.toastr.error('Por favor, selecione o <b>arquivo</b> para anexar')
            return false


        } else {

            
            if (file.substr(file.length - 3).toLowerCase() != 'pdf') {

                window.toastr.error('Por favor, apenas arquivos no formato <b>PDF</b> para anexar!')
                return false


            }

        }

        

        if (descricaoAnexo == '') {

            window.toastr.error('Por favor, informe a descrição do arquivo que deseja anexar')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma anexar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileAnexoDemanda");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("descricao", descricaoAnexo); // string junto ao formData.
                    formData.append("demanda_id", demanda_id); // string junto ao formData.
                    formData.append("ad_usr", usuario_id); // string junto ao formData.
                    formData.append("tipoDoc", tipoDoc)
                    
                    //console.log('altera')
                    //return false

                    axios.post(`${process.env.REACT_APP_API}/demandaDocumentoUpload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        //window.$('.modal-CompraImporta').modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()
                        
                        carregaDocumentos()
                        setDescricaoAnexo('')
                        setFile('')
                        props.totalizaDocumentos()
                        

                        window.toastr.success('Arquivo anexado com sucesso!')



                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const apagaArquivo = (documentoDemanda_id) => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar este anexo desta solicitação?',
		    buttons: {
		        Sim: function () {


                                    
                        api.delete(`demandaDocumentoUpload/${documentoDemanda_id}`).then((result) => {

                            //console.log(result.data)
                            carregaDocumentos()
                            window.toastr.success('Documento removido com sucesso!')
                            //props.carregaPedidos()
                            props.totalizaDocumentos()
                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const carregaDocumentos = () => {


        api.get(`demandaDocumentoUpload/${demanda_id}/${tipoDoc}`).then((result) => {

            //console.log(result.data, '!')
            setResultadoDocumentos(result.data.map((rs) =>
            
                <tr>
                        
                    <td>{rs.descricao}</td>
                    <td style={{ textAlign: 'center'}}>
                        <button type="button" className="btn btn-inline btn-primary-outline"  onClick={() => window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${rs.arquivoNome}`) }>Download</button>
                    </td>
                    <td > 

                        <button style={{ display: demanda_id == 7 || demanda_id == 3 ? 'none' : 'table-row'}} type="button" className="btn btn-inline btn-danger-outline" onClick={() => apagaArquivo(rs.documentoDemanda_id)}>X</button>

                    </td>
                </tr>                
            
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }

    useEffect(() => {

        if (demanda_id) {

            carregaDocumentos()

        }

    }, [demanda_id])

    return (

        <div>
            <div className="row">
                <div className="col-md-12" style={{ display: demandaStatus_id == 10 || demandaStatus_id == 11 ? 'none' : 'table-row'}}>
                    <fieldset className="form-group">
                        <label className="form-label">Descrição do documento</label>
                        <input type="text" className="form-control" value={descricaoAnexo} maxLength={500} onChange={event => setDescricaoAnexo(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                    </fieldset>

                </div>
                <div className="col-md-9" style={{ display: demandaStatus_id == 10 || demandaStatus_id == 11 ? 'none' : 'table-row'}}>
                    <fieldset className="form-group">
                        <label className="form-label">Arquivo</label>
                        <input type="file" className="form-control" id="fileAnexoDemanda" value={file} onChange={event => setFile(event.target.value)} />

                    </fieldset>
                </div>
                <div className="col-md-3" style={{ display: demandaStatus_id == 10 || demandaStatus_id == 11 ? 'none' : 'table-row'}}>
                    <fieldset className="form-group">
                        <label className="form-label">&nbsp;</label>
                        <button type="button" className="btn btn-inline btn-info-outline" onClick={() => salvaDocumento()} >Salvar documento</button>

                    </fieldset>
                </div>
                <div className="col-md-12" >

                    <table id="table-sm" className="table table-hover">
                        <thead>
                                <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    <th>
                                        
                                    Descrição                                 

                                    </th>
                                        
                                    <th>
                                        
                                    Arquivo                                 

                                    </th>
                                    <th >
                                        
                                    &nbsp;                                 

                                    </th>
                                
                                </tr>
                                
                        </thead>
                        {resultadoDocumentos}
                        
                    </table>

                </div>
            </div>
        </div>

    )

}


export default DemandaDocumentos