import { useState, useEffect } from "react"
import api from "../components/api"
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney } from "../functions/generalFunctions"
import moment from 'moment'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";


const DemandaPedido = (props) => {

    const perfil = sessionStorage.getItem('perfil')
    const assinaReservaRecurso = sessionStorage.getItem('assinaReservaRecurso')

    const demanda_id = props.demanda_id
    const pedidoCompra_id = props.pedidoCompra_id
    const possuiAnexoAssinatura = props.possuiAnexoAssinatura
    const possuiReservaRecursos = props.possuiReservaRecursos
    

    const [resultadoItens, setResultadoItens] = useState([])
    
    const [itens, setItens] = useState([])
    

    const [numeroPedido, setNumeroPedido] = useState('')
    const [numeroDespacho, setNumeroDespacho] = useState('')

    const [pedidoCompraStatus_id_anterior, setPedidoCompraStatus_id_anterior] = useState()
    const [pedidoCompraStatus_id, setPedidoCompraStatus_id] = useState()
    const [resultadoStatusPedidoCompra, setResultadoStatusPedidoCompra] = useState([])

    const [totalAnexos, setTotalAnexos] = useState(0)
    

    const [totalHistorico, setTotalHistorico] = useState(0)
    const [totalItens, setTotalItens] = useState(0)
    const [numeroProcesso, setNumeroProcesso] = useState()
    
    const [autorizado, setAutorizado] = useState(false)
    const [autorizacao, setAutorizacao] = useState('')

    const [resultadoHistorico, setResultadoHistorico] = useState([])
    const [resultadoAnexos, setResultadoAnexos] = useState([])

    const [colorCentroCusto, setColorCentroCusto] = useState('white')
    const [centroCustoDescri, setCentroCustoDescri] = useState('')

    const [dataAtendimentoF, setDataAtendimentoF] = useState('')

    const [justificativa, setJustificativa] = useState('')
    const [solicitante, setSolicitante] = useState(sessionStorage.getItem('solicitante'))
    const [nome, setNome] = useState('')
    const [identificacao, setIdentificacao] = useState('')
    const [urgente, setUrgente] = useState(false)
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])

    const [resultadoCentroCusto, setResultadoCentroCusto] = useState('')

    const [valorAutorizado, setValorAutorizado] = useState()

    const [resultadoNatureza, setResultadoNatureza] = useState()    
    
    const [codigoNatureza, setCodigoNatureza] = useState()
    const [natureza, setNatureza] = useState('')
    const [resultadoItemContabil, setResultadoItemContabil] = useState()
    const [itemContabil, setItemContabil] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [resultadoArea, setResultadoArea] = useState()
    const [area, setArea] = useState('')
    const [resultadoSubItem, setResultadoSubItem] = useState()
    const [subItem, setSubItem] = useState('')
    
    const [assinatura, setAssinatura] = useState('')

    const [chaveAssinaturaReservaRecurso, setChaveAssinaturaReservaRecurso] = useState('')
    const [cpfAssinaturaReservaRecursos, setCpfAssinaturaReservaRecursos] = useState('')
    const [assinadoReservaRecurso, setAssinadoReservaRecurso] = useState(false)
    const [dataAssinaturaReservaRecurso, setDataAssinaturaReservaRecurso] = useState('')
    const [nomeAssinaturaReservaRecurso, setNomeAssinaturaReservaRecurso] = useState('')
    
        


    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

          //console.log(result.data)

            setResultadoSolicitantes(result.data.map((rs) => 

                <option value={rs.solicitante}>{rs.solicitante}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    const carregaPedidoHistorico = () => {


        if (pedidoCompra_id) {


            api.get(`pedidoCompraListaHistorico/${pedidoCompra_id}`).then((result) => {

                //console.log(result.data)
                setTotalHistorico(result.data.length)

                setResultadoHistorico(result.data.map((rs) => 

                    <tr  style={{ fontSize: '11px', textAlign: 'center'}}>
                        <td>
                            {rs.ad_new}
                        </td>
                        <td>
                            {rs.status}
                        </td>
                        <td>
                            {rs.nomeSolicitante}
                        </td>
                        <td>
                            {rs.observacao}
                        </td>
                        
                        
                    </tr>

                ))

            }).catch((err) => {

                console.log(err.response)

            })



        }


    }
    
    const carregaPedido = () => {

        api.get(`pedidoCompra/${pedidoCompra_id}`).then((result) => {

            //console.log(result.data)

            var data = result.data
            //console.log(data, '!!')
            var dataAtendimentoF = ''

            if (data.dataAtendimento) {

                var dateParts = data.dataAtendimento.split("/");
                var dataAtendimentoF = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

            }

            

            setChaveAssinaturaReservaRecurso(data.chaveAssinaturaReservaRecurso)
            setCpfAssinaturaReservaRecursos(data.cpfAssinaturaReservaRecurso)
            setAssinadoReservaRecurso(data.assinadoReservaRecurso)
            setDataAssinaturaReservaRecurso(data.dataAssinaturaReservaRecurso)
            setNomeAssinaturaReservaRecurso(data.nomeAssinaturaReservaRecurso)

            if (data.assinadoReservaRecurso) {

                setAssinatura(`Em ${data.dataAssinaturaReservaRecurso} por ${data.cpfAssinaturaReservaRecurso} - ${data.nomeAssinaturaReservaRecurso} \nChave ${data.chaveAssinaturaReservaRecurso}`)

            }

            setNome(data.nomeSolicitante)
            setJustificativa(data.justificativa)
            setSolicitante(data.solicitante)
            setItens(data.itens)
            setNumeroProcesso(data.numeroProcesso)
            setPedidoCompraStatus_id_anterior(data.pedidoCompraStatus_id)
            setPedidoCompraStatus_id(data.pedidoCompraStatus_id)
            setNumeroPedido(data.numeroPedido == null ? '' : data.numeroPedido)
            setAutorizado(data.autorizado ? data.autorizado : false)
            setIdentificacao(data.identificacao)
            setDataAtendimentoF(dataAtendimentoF)
            setUrgente(data.urgente ? data.urgente : false)
            setNumeroDespacho(data.numeroDespacho)

            setCentroCustoDescri(data.centroCustoDescri)
            
            setCodigoNatureza(data.codigoNatureza)
            setNatureza(data.natureza)
            setItemContabilDescri(data.itemContabilDescri)
            setItemContabil(data.itemContabil)
            setArea(data.area)
            setSubItem(data.subItem)
            setValorAutorizado(data.valorAutorizado ? fmtMoney(data.valorAutorizado, 2).replaceAll('R$', '') : '')


            

            if (data.autorizado) {

                setAutorizacao(`Em ${data.dataAutorizacao} por ${data.cpfAutorizacao} - ${data.nomeAutorizacao} \nChave ${data.chaveAutorizacao}`)

            }

        }).catch((err) => {

            console.log(err)

        })

    }



    const carregaAnexos = () => {

        if (pedidoCompra_id) {

            api.get(`pedidoCompraListaArquivos/${pedidoCompra_id}`).then((result) => {

                //console.log(result.data)
                setTotalAnexos(result.data.length)

                setResultadoAnexos(result.data.map((rs) =>

                    <tr>
                        
                        <td>{rs.descricao}</td>
                        <td style={{ textAlign: 'center'}}>
                            <button type="button" className="btn btn-inline btn-primary-outline"  onClick={() => window.open(`${process.env.REACT_APP_API}/upload/pedidoCompra/${rs.arquivoNome}`) }>Download</button>
                        </td>
                        
                    </tr>                
                
                ))

            }).catch((err) => {

                console.log(err)

            })

        }


    }

    useEffect(() => {

        //window.$('#collapseOne').toogle();
        //console.log('aqui')
                
        
        limpa()

        if (pedidoCompra_id) {

            carregaPedido()
            carregaSolicitantes()
            carregaPedidoHistorico()
            carregaAnexos()

        }

        //console.log(possuiAnexoAssinatura)
        //console.log(possuiReservaRecursos)

    }, [pedidoCompra_id])



    const salvaAssinatura = () => {

        if (valorAutorizado == '' || valorAutorizado == null) {

            window.toastr.error('<b>Valor autorizado</b> não pode ficar em branco!')
            return false;

        }


        var dataPost = {

            pedidoCompra_id: pedidoCompra_id,            
            valorAutorizado: valorAutorizado ? valorAutorizado.replaceAll('.', '').replaceAll(',','.') : 0,
            
        }

        //console.log(dataPost)


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Salvar',
            content: 'Confirma salvar os dados da assinatura?',
            buttons: {
                Ok: function () {

                    api.put('pedidoCompraAssinatura', dataPost).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Salvo com sucesso!')


                    }).catch((err) => {

                        console.log(err)

                    })

                },
                Cancelar: function () {


                },
            },

        });


    }


    

    const salvaReservaRecursos = () => {


        if (codigoNatureza == '' || codigoNatureza == null) {

            window.toastr.error('<b>Natureza</b> não pode ficar em branco!')
            return false;

        }


        if (itemContabil == '' || itemContabil == null) {

            window.toastr.error('<b>Item Contábil</b> não pode ficar em branco!')
            return false;

        }


        if (area == '' || area == null) {

            window.toastr.error('<b>Área</b> não pode ficar em branco!')
            return false;

        }


        if (subItem == '' || subItem == null) {

            window.toastr.error('<b>Sub-item</b> não pode ficar em branco!')
            return false;

        }



        var dataPost = {

            pedidoCompra_id: pedidoCompra_id,
            subItem: subItem,
            area: area,
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            natureza: natureza,
            itemContabilDescri: itemContabilDescri,

        }

        //console.log(dataPost)
        //return false


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Salvar',
            content: 'Confirma salvar os dados da Reserva de Recurso!?',
            buttons: {
                Ok: function () {

                    api.put('pedidoCompraReservaRecursos', dataPost).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Salvo com sucesso!')

                        //<button type="button" className="btn btn-inline btn-info-outline" onClick={() => assinarReservaRecurso()} style={{ display: (perfil == 'Administrador' || assinaReservaRecurso == 'true') && assinadoReservaRecurso == false ? 'table-row' : assinadoReservaRecurso == true ? 'none' : 'table-row' }}  >Assinar documento</button>

                        console.log(perfil)
                        console.log(assinaReservaRecurso)
                        console.log(assinadoReservaRecurso)

                        if ((perfil == 'Administrador' || assinaReservaRecurso == 'true' ) && (assinadoReservaRecurso == false || assinadoReservaRecurso == null)) {


                    
                            assinarReservaRecurso()


                        }


                    }).catch((err) => {

                        console.log(err)

                    })

                },
                Cancelar: function () {


                },
            },

        });

        


    }



    const carregaNaturezas = () => {


        var dataPost = {

            tipo: "DESPESAS",

        }

        api.post('listaNaturezaFiltro', dataPost).then((result) => {

            setResultadoNatureza(result.data.map((rs) =>
            
                <option value={rs.codigoNatureza}>{rs.codigoNatureza} - {rs.natureza}</option>
            
            ))

        }).catch((err) => {

            console.log(err)

        })

    }


    const carregaItemContabil = () => {

        var dataPost = {

            codigoNatureza: codigoNatureza,
            tipo: "DESPESAS",

        }

        //console.log(dataPost)

        setResultadoItemContabil()

        api.post('listaItemContabilFiltro', dataPost).then((result) => {

            //console.log(result.data)
            
            setResultadoItemContabil(result.data.map((rs) =>
            
                <option value={rs.itemContabil}>{rs.itemContabil} - {rs.itemContabilDescri}</option>
            
            ))

        }).catch((err) => {

            console.log(err)

        })

    }


    const carregaAreas = () => {

        var dataPost = {

            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            tipo: 'DESPESAS',

        }

        console.log(dataPost)
        
        setResultadoArea()

        api.post('listaAreaFiltro', dataPost).then((result) => {

            
            setResultadoArea(result.data.map((rs) =>
            
                <option value={rs.area}>{rs.area}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    
    const carregaSubItem = () => {

        var dataPost = {

            area: area,
            tipo: 'DESPESAS',
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,

        }

        setResultadoSubItem()

        api.post('listaSubItemFiltro', dataPost).then((result) => {

            setResultadoSubItem(result.data.map((rs) =>
            
                <option value={rs.fluxoCaixa}>{rs.fluxoCaixa}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }




    const pdfReservaRecursos = () => {


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
            title: 'Aviso',
            content: 'Antes de gerar o PDF certifique-se de que as informações foram salvas!',
            buttons: {
                Ok: function () {

                    
                    reservaRecursosPDF()


                },
                Cancelar: function () {


                },
            },

        });

    }



    const reservaRecursosPDF = () => {


        api.get(`reservaRecursosPDF/${pedidoCompra_id}`).then((result) => {

            //console.log(result.data)
            window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
            //window.$('#modalDespacho').modal('hide')

        }).catch((err) => {

            console.log(err.response)

        })



    }



    const assinarReservaRecurso = () => {

        var nome = sessionStorage.getItem('nome')
        var cpf = sessionStorage.getItem('cpf')
       
        var dataPost = {

            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),

        }



        if (valorAutorizado == '' || valorAutorizado == null) {

            window.toastr.error('<b>Valor autorizado</b> não pode ficar em branco!')
            return false;

        }

        

        if (codigoNatureza == '' || codigoNatureza == null) {

            window.toastr.error('<b>Natureza</b> não pode ficar em branco!')
            return false;

        }


        if (itemContabil == '' || itemContabil == null) {

            window.toastr.error('<b>Item Contábil</b> não pode ficar em branco!')
            return false;

        }


        if (area == '' || area == null) {

            window.toastr.error('<b>Área</b> não pode ficar em branco!')
            return false;

        }


        if (subItem == '' || subItem == null) {

            window.toastr.error('<b>Sub-item</b> não pode ficar em branco!')
            return false;

        }

        

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirma assinar este documento?',
		    content: `${cpf} - ${nome}`,
		    buttons: {
		        Sim: function () {


                                    
                        api.put(`assinaReservaRecurso/${pedidoCompra_id}/${demanda_id}`, dataPost).then((result) => {

                            

                            var data = result.data

                            setChaveAssinaturaReservaRecurso(data.chaveAssinaturaReservaRecurso)
                            setCpfAssinaturaReservaRecursos(data.cpfAssinaturaReservaRecurso)
                            setAssinadoReservaRecurso(data.assinadoReservaRecurso)
                            setDataAssinaturaReservaRecurso(data.dataAssinaturaReservaRecurso)
                            setNomeAssinaturaReservaRecurso(data.nomeAssinaturaReservaRecurso)

                            if (data.assinadoReservaRecurso) {

                                setAssinatura(`Em ${data.dataAssinaturaReservaRecurso} por ${data.cpfAssinaturaReservaRecurso} - ${data.nomeAssinaturaReservaRecurso} \nChave ${data.chaveAssinaturaReservaRecurso}`)
                
                            }

                            /*
                            carregaPedido()
                            carregaPedidoHistorico()
                            props.carregaPedidos()

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: `Autorização`,
                                content: `${ autorizado ? 'Solicitação com autorização desfeita' : 'Pedido autorizado' }`,
                                buttons: {
                                    OK: function () {

                                        pdfPedido(result.data.pedidoCompra_id)

                                    }
                                },
                    
                            });
                            */

                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }   




    useEffect(() => {

        //console.log(possuiAnexoAssinatura, 'possui')
        //console.log(possuiAnexoAssinatura, '!!')

        if (possuiAnexoAssinatura == true) {

            carregaNaturezas()     
                   
        }

    }, [possuiAnexoAssinatura])

    /*
    useEffect(() => {

        //console.log(possuiAnexoAssinatura, 'possui')
        //console.log(possuiReservaRecursos, '!!!')

        if (possuiReservaRecursos == true) {

            carregaNaturezas()            
        }

    }, [possuiReservaRecursos])
    */

    useEffect(() => {

        if (codigoNatureza) {

            carregaItemContabil()

        }

    }, [codigoNatureza])


    useEffect(() => {

        if (itemContabil) {

            carregaAreas()

        }

    }, [itemContabil])



    useEffect(() => {

        if (area) {

            carregaSubItem()

        }

    }, [area])



    const limpa = () => {


        setArea('')
        setSubItem('')
        setCodigoNatureza('')
        setItemContabil('')
        setValorAutorizado('')
        setNatureza('')
        setItemContabilDescri('')


        setChaveAssinaturaReservaRecurso('')
        setCpfAssinaturaReservaRecursos('')
        setAssinadoReservaRecurso(false)
        setDataAssinaturaReservaRecurso('')
        setNomeAssinaturaReservaRecurso('')

    }



    useEffect(() => {

        limpa()
        

    }, [])


    useEffect(() => {

        console.log(assinadoReservaRecurso, 'assinado')

    }, [assinadoReservaRecurso])


    useEffect(() => {


        carregaNaturezas()
        
       
    }, [pedidoCompra_id])


    useEffect(() => {

        //console.log(itens, '!!')

        if (itens) {


            setResultadoItens(itens.map((rs) =>

                <tr>
                    <td style={{ textAlign: 'center'}}>
                        {rs.tipoItem == 'O' ? 'OPME' : rs.tipoItem == 'S' ? 'Serviço' : rs.tipoItem == 'M' ? 'Material' : rs.tipoItem == 'P' ? 'Matéria-prima' : 'Investimento'}
                    </td>
                    <td>
                        {rs.tipoItem == 'O' ? rs.opmeDescri : rs.tipoItem == 'S' || rs.tipoItem == 'I' || (rs.tipoItem == 'M' && rs.origemItem == 'F') ? rs.descricaoServico : rs.tipoItem == 'P' ? rs.materiaPrimaDescri : rs.siafisicoPregao + ' - ' + rs.itemDescri}
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        {rs.tipoItem == 'S' || rs.tipoItem == 'I' ? 'N/A' : ((rs.tipoItem == 'M' && rs.origemItem == 'F') || rs.tipoItem == 'P' || rs.tipoItem == 'O')  && pedidoCompra_id ? rs.unidFornecimentoMateriaPrima : rs.unidFornecimento}
                        
                    </td>
                    <td style={{ textAlign: 'right'}}>
                        {rs.quantidade.toLocaleString('pt-BR')}
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        <button type="button" className="btn btn-inline-sm btn-danger" style={{ display: pedidoCompra_id ? 'none' : 'table-row'}}  onClick={() => removeItem(rs.key ? rs.key : rs.itemPedido_id)}>X</button>
                    </td>
                </tr>
        
            ))

        }

        setTotalItens(itens.length)

    }, [itens])

    return (

        <div>

            <section class="widget widget-accordion" id="accordion" role="tablist" aria-multiselectable="true">
                <article class="panel">
                    <div class="panel-heading" role="tab" id="headingOne">
                        <a data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                            Solicitação
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-collapse-in">
                            

                                        <div className="row">
                                            <div className="col-md-12" style={{ display: pedidoCompra_id ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Número pedido</label>
                                                    <input type="text" className="form-control" value={numeroPedido} disabled style={{ backgroundColor: '#cdcbf2'}} />
                                                </fieldset>
                                            </div>

                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label">Solicitante</label>
                                                    <input type="text" className="form-control" value={nome} disabled />
                                                </fieldset>
                                            </div>
                                        
                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Área solicitante</label>
                                                    <select className="form-control" value={solicitante} disabled={ true } onChange={event => setSolicitante(event.target.value)} >
                                                        <option value="">[Selecione]</option>
                                                        {resultadoSolicitantes}
                                                    
                                                    </select>
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Identificação</label>
                                                    <input className="form-control" value={identificacao} maxlength={25} disabled={ pedidoCompra_id ? true : false} onChange={event => setIdentificacao(event.target.value)} />
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Data atendimento</label>
                                                    
                                                    <DatePicker   selected={dataAtendimentoF || null} disabled={ pedidoCompra_id ? true : false} onChange={(date) => setDataAtendimentoF(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: pedidoCompra_id ? '#EFEFEF' : '#FFF'}} /> } 
                                                    />
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            


                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Justificativa</label>
                                                    <textarea  className="form-control" rows="4" maxlength="500" value={justificativa} onChange={event => setJustificativa(event.target.value)} disabled={ pedidoCompra_id ? true : false} />
                                                    
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-12" style={{ display: centroCustoDescri ? 'table-row' : 'none'}}>
                                                
                                                
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Centro de Custo</label>
                                                    <textarea type="text" className="form-control" value={centroCustoDescri} onChange={event => setCentroCustoDescri(event.target.value)} onFocus={() => {setCentroCusto_id();setCentroCustoDescri('');setCentroCusto('')}} disabled={ pedidoCompra_id ? true : false }/>
                                                    {resultadoCentroCusto}
                                                    
                                                </fieldset>

                                            </div>

                                            <div className="col-md-12" style={{ display: autorizado == true ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Autorizado</label>
                                                    <textarea rows="2" className="form-control" value={autorizacao} disabled style={{ backgroundColor: '#e9f2cb'}}  />
                                                </fieldset>
                                            </div>
                                            
                                        </div>


                        </div>
                    </div>
                </article>
                <article class="panel">
                    <div class="panel-heading" role="tab" id="headingTwo">
                        <a class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                            Anexos&nbsp;<span class="label label-pill label-primary">{totalAnexos}</span>
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div class="panel-collapse-in">
                            

                            <table id="table-sm" className="table table-hover">
                                <thead>
                                        <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                            
                                            <th>
                                                
                                            Descrição                                 

                                            </th>
                                                
                                            <th>
                                                
                                            Arquivo                                 

                                            </th>
                                         
                                        
                                        </tr>
                                        
                                </thead>
                                {resultadoAnexos}
                                
                            </table>


                        </div>
                    </div>
                </article>
                <article class="panel">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <a class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                            Itens&nbsp;<span class="label label-pill label-success">{totalItens}</span>
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-collapse-in">
                                
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        Tipo

                                    </th>
                                    <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        Descrição                                

                                    </th>
                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        
                                        Unid. Fornecimento

                                    </th>
                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        
                                        Qtde.

                                    </th>
                                    
                                </tr>
                                        
                                </thead>
                                <tbody>

                                    {resultadoItens}
                                    
                                </tbody>
                            
                            </table>

                        </div>
                    </div>
                </article>
                <article class="panel">
                    <div class="panel-heading" role="tab" id="headingFour">
                        <a class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour">
                            Histórico&nbsp;<span class="label label-pill label-warning">{totalHistorico}</span>
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div class="panel-collapse-in">
                            
                                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                                            <thead>
                                                    <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                        
                                                        <th>
                                                            
                                                        Data                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Situação                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Usuário                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Obs.

                                                        </th>
                                                      
                                                    </tr>
                                                    
                                            </thead>
                                            {resultadoHistorico}
                                            
                                        </table>


                        </div>
                    </div>
                </article>
                <article class="panel" style={{ display: possuiAnexoAssinatura == true ? 'block' : 'none' }}>
                    <div class="panel-heading" role="tab" id="headingFive">
                        <a class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive">
                            Assinatura
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                        <div class="panel-collapse-in">
                            
                                        
                            <div className="row">

                                    <div className="col-md-12">
                                        <fieldset className="form-group">
                                            <label className="form-label" >Valor autorizado R$</label>
                                            <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valorAutorizado} onChange={event => setValorAutorizado(event.target.value)} maxLength={150} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } />
                                        
                                        </fieldset>
                                    </div>


                                <div className="col-md-12">

                                    <div align="left">

                                        <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaAssinatura()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == false ? 'table-row' : assinadoReservaRecurso == true ? 'none' : 'table-row' }} >Salvar valor autorizado</button>

                                    </div>


                                </div>

                            </div>





                        </div>
                    </div>
                   
                </article>
                <article class="panel"  style={{ display: possuiAnexoAssinatura == true ? 'block' : 'none' }}>
                    <div class="panel-heading" role="tab" id="headingSix">
                        <a class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix">
                            Reserva de Recursos
                            <i class="font-icon font-icon-arrow-down"></i>
                        </a>
                    </div>
                    <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                        <div class="panel-collapse-in">
                            
                                        
                            <div className="row">

                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Natureza</label>
                                        <select className="form-control" value={codigoNatureza} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => {setCodigoNatureza(event.target.value);setNatureza(event.target[event.target.selectedIndex].text)}}>
                                            <option value="">[Selecione]</option>
                                            {resultadoNatureza}
                                        </select>
                                    
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Item Contábil</label>
                                        <select className="form-control" value={itemContabil} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => {setItemContabil(event.target.value);setItemContabilDescri(event.target[event.target.selectedIndex].text)}}>
                                            <option value="">[Selecione]</option>
                                            {resultadoItemContabil}
                                        </select>
                                    
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Área</label>
                                        <select className="form-control" value={area} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } onChange={event => setArea(event.target.value)}>
                                            <option value="">[Selecione]</option>
                                            {resultadoArea}
                                        </select>
                                    
                                    </fieldset>
                                </div>

                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" >Sub-item</label>
                                        <select className="form-control" value={subItem} onChange={event => setSubItem(event.target.value)} disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } >
                                            <option value="">[Selecione]</option>
                                            {resultadoSubItem}
                                        </select>
                                    
                                    </fieldset>
                                </div>


                                <div className="col-md-12" style={{ display: assinadoReservaRecurso == true ? 'table-row' : 'none'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Assinatura</label>
                                        <textarea rows="2" className="form-control" disabled={  (perfil == 'ConsultaCompras' || perfil == 'Financeiro') && assinadoReservaRecurso == true ?  true : assinadoReservaRecurso == true ? true : false } value={assinatura} style={{ backgroundColor: '#e9f2cb'}}  />
                                    </fieldset>
                                </div>



                                <div className="col-md-12">

                                    <div align="right">

                                        
                                        <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => pdfReservaRecursos()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == true ? 'table-row' : 'none' }} >PDF Reserva de Recursos</button>
                                        <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaReservaRecursos()} style={{ display: (perfil == 'Administrador' || perfil == 'Compras') && assinadoReservaRecurso == false ? 'table-row' : assinadoReservaRecurso == true ? 'none' : 'table-row' }}  >Salvar Reserva de Recursos</button>

                                    </div>


                                </div>

                                            

                            </div>


                        </div>
                    </div>
                   
                </article>


                
            </section>

        </div>

    )

}

export default DemandaPedido